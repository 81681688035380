import React from 'react';
import './App.css';
import Album from "./components/Album";
import {MuiThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";

const theme = createMuiTheme({
    palette: {
        // primary: green,
        primary: {
            main: '#0073ff',
        },
        secondary: {
            main: '#ffffff',
        },
    },
    status: {
        danger: 'orange',
    },
});

const RecaptchaAlbum = withGoogleReCaptcha(Album);

class App extends React.Component {
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <RecaptchaAlbum/>
            </MuiThemeProvider>
        );
    }
}

export default App;