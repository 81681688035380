import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Paper from "@material-ui/core/Paper";
import ChipInput from "material-ui-chip-input";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Background = "https://source.unsplash.com/random"

const styles = theme => ({
    // root: {
    //     '& .MuiTextField-root': {
    //         margin: theme.spacing(10),
    //         width: 500,
    //     },
    // },
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
    icon: {
        marginRight: theme.spacing(3),
    },
    heroContent: {
        // backgroundColor: theme.palette.background.paper,
        backgroundColor: "#999999",
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)), url(${Background})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        padding: theme.spacing(10, 0, 13),
    },
    division: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(6),
    },
    rightToolbar: {
        marginLeft: 'auto',
    },
    paper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
});

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            <Link color="inherit" href="https://github.com/SoloSynth1" target="_blank">
                Orix "SoloSynth1" Au Yeung
            </Link>{', '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function Title() {
    return (
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            Need to find text inside an image?
        </Typography>
    )
}

function Subtitle() {
    return (
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
            Upload an image. Tell us what to find. We will highlight them for you.
        </Typography>
    )
}

class HeroContainer extends React.Component {
    render() {
        return (
            <Container maxWidth="sm" className={this.props.classes.container}>
                <Title/>
                <Subtitle/>
            </Container>
        )
    }
}

class ImageInputGrid extends React.Component {

    renderImage() {
        if (this.props.file) {
            return (
                <Grid container spacing={2} justify="center">
                    <Grid item>
                        <img src={this.props.file} width={"50%"} style={{
                            "display": "block",
                            "margin": "auto"
                        }}/>
                    </Grid>
                </Grid>
            )
        }
    }

    render() {
        return (
            <div>
                <Grid container spacing={2} justify="center">
                    <Grid item>
                        <Typography component="h1" variant="h5" align="center" color="textPrimary" gutterBottom>
                            1. Select an image.
                        </Typography>
                        <input
                            accept="image/*"
                            style={{"display": 'none'}}
                            id="contained-button-file"
                            type="file"
                            value={this.props.inputValue} onChange={(evt) => {
                            this.props.updateInputValue(evt);
                        }}
                        />
                    </Grid>
                    <Grid item>
                        <label htmlFor="contained-button-file">
                            <Button variant="contained"
                                    color="secondary"
                                    component="span">
                                BROWSE
                            </Button>
                        </label>
                    </Grid>
                </Grid>
                {this.renderImage()}
            </div>
        )
    }
}

class ChipsGrid extends React.Component {
    render() {
        return (
            <Grid container spacing={2} justify="center">
                <Grid item>
                    <Typography component="h1" variant="h5" align="center" color="textPrimary"
                                gutterBottom>
                        2. Add text to find.
                    </Typography>
                </Grid>
                <Grid item>
                    <ChipInput
                        defaultValue={this.props.defaultValue}
                        onChange={(chips) => this.props.handleChipsChange(chips)}
                    />
                </Grid>
            </Grid>
        )
    }
}

class UploadGrid extends React.Component {
    render() {
        return (
            <Grid container spacing={2} justify="center">
                <Grid item>
                    <Typography component="h1" variant="h5" align="center" color="textPrimary" gutterBottom>
                        3. Upload.
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={() => this.props.handleUpload()}>
                        UPLOAD
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

class Album extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: "",
            basename: "",
            base64: "",
            file: null,
            chips: ['香港', 'Hong Kong'],
            backdrop: false,
            dialog: false,
            response: "",
            snackbar: false,
            errorMessage: "",
            token: ""
        }
    }

    async generateNewToken() {
        const token = await this.props.googleReCaptchaProps.executeRecaptcha('process');
        console.log(token);
        this.setState({
            token: token,
        });
    }

    componentDidMount() {
        this.generateNewToken();
    }

    getImageBase64(file) {
        if (file) {
            if (file.type.match('image.*')) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    this.setState({
                        base64: reader.result,
                        file: URL.createObjectURL(file),
                    })
                    return Promise.resolve(reader.result);
                }.bind(this);
                reader.onerror = function (error) {
                    this.showErrorSnackBar('Error occurred when reading image.');
                    return "";
                };
            } else {
                this.showErrorSnackBar("The selected file is not an image!");
                this.setState({
                    base64: "",
                    file: null,
                })
                return "";
            }
        }
    }

    updateInputValue(evt) {
        const savedTarget = evt.target;
        const inputValue = savedTarget.value;
        if (savedTarget.files.length >= 1) {
            const inputFile = savedTarget.files[0];
            this.getImageBase64(inputFile);
            this.setState({
                inputValue: inputValue,
                basename: inputFile.name,
            })
        }
    }

    handleChipsChange(chips) {
        this.setState({chips: chips})
    }

    handleUpload() {
        const criteria = this.state.chips;
        const base64 = this.state.base64;
        if (criteria.length >= 1 && base64 !== "") {
            this.setState({backdrop: true});
            const requestBody = {
                image: base64.split(",")[1],
                criteria: criteria,
                token: this.state.token
            };
            fetch("https://gcp-vision-ocr-s6zevkfqfq-de.a.run.app/process",
                {
                    method: 'POST',
                    body: JSON.stringify(requestBody),
                    headers: {'Content-Type': 'application/json'}
                })
                .then(res => res.json())
                .then(data => {
                    if ("error" in data) {
                        const errMsg = data['error'];
                        throw new Error(errMsg);
                    } else {
                        const annotatedImage = data.annotatedImage;
                        this.setState({
                            response: annotatedImage,
                            backdrop: false,
                            dialog: true,
                        });
                    }
                })
                .catch(err => {
                    this.showErrorSnackBar("Error occurred. reason: " + err.toString());
                    this.setState({
                        response: "",
                        backdrop: false,
                        dialog: false,
                    });
                });
        } else {
            this.showErrorSnackBar("Please select an image AND add text to find.");
        }
        this.generateNewToken();
    }

    showErrorSnackBar(msg) {
        this.setState({
            snackbar: true,
            errorMessage: msg,
        })
    }

    handleSnackBarClose() {
        this.setState({
            snackbar: false,
            errorMessage: "",
        })
    }

    renderErrorSnackBar() {
        const snackbar = this.state.snackbar;
        if (snackbar) {
            const errorMessage = this.state.errorMessage;
            return (
                <Snackbar open={snackbar} autoHideDuration={6000} onClose={() => this.handleSnackBarClose()}>
                    <Alert onClose={() => this.handleSnackBarClose()} severity="error">
                        {errorMessage}
                    </Alert>
                </Snackbar>
            )
        }
    }

    handleDialogClose() {
        this.setState({dialog: false});
    }

    handleResponseImage() {
        const response = this.state.response;
        if (response !== "") {
            return (
                <div>
                    <a href={response} target={"_blank"} rel="noopener noreferrer">
                        <img width="100%" src={response} alt={"result"}/>
                    </a>
                </div>
            );
        }
    }

    render() {
        const {classes} = this.props;
        return (
            <React.Fragment>
                <CssBaseline/>
                <main>
                    <div className={classes.heroContent}>
                        {/* Hero unit */}
                        <HeroContainer classes={classes}/>
                        <Container maxWidth="sm" className={classes.container}>
                            <Paper className={classes.paper}>
                                <div className={classes.division}>
                                    <ImageInputGrid file={this.state.file}
                                                    updateInputValue={this.updateInputValue.bind(this)}/>
                                </div>
                                <div className={classes.division}>
                                    <ChipsGrid defaultValue={this.state.chips}
                                               handleChipsChange={this.handleChipsChange.bind(this)}/>
                                </div>
                                <div className={classes.division}>
                                    <UploadGrid handleUpload={this.handleUpload.bind(this)}/>
                                </div>
                            </Paper>
                        </Container>
                    </div>
                    <Backdrop className={classes.backdrop}
                              open={this.state.backdrop}
                    >
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                    <Dialog onClose={() => this.handleDialogClose()} aria-labelledby="simple-dialog-title"
                            open={this.state.dialog}>
                        <DialogTitle id="simple-dialog-title">Done! Click to enlarge :)</DialogTitle>
                        {this.handleResponseImage()}
                    </Dialog>
                    {this.renderErrorSnackBar()}
                </main>
                {/* Footer */}
                <footer className={classes.footer}>
                    <Typography variant="h6" align="center" gutterBottom>
                        Powered by <Link href={"https://cloud.google.com/vision"} color="primary" target="_blank">Google
                        Cloud Vision</Link>
                    </Typography>
                    <Copyright/>
                </footer>
                {/* End footer */}
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(Album);
